import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Injectable, inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/shared/services/shared.service';
import { PermissionsEnum } from 'src/app/shared/model/permissions.model';
import { ContextService } from 'src/app/shared/services/context.service';
import { FeatureService } from 'src/app/shared/services/feature.service';

@Injectable({
    providedIn: 'root',
})
class AccessGuardService {
    constructor(
        private router: Router,
        private authService: AuthService,
        private permissionsService: PermissionsService,
        private sharedService: SharedService,
        private contextService: ContextService,
        private featureService: FeatureService
    ) { }

    // We can implement this to improve performance. But since we don't have any performance diffictulties then we can bail the complication
    // canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    //     const requiresLogin = route.data?.requiresLogin ?? !(route.data?.onlyAnonymous ?? false);
    //     const onlyAnonymous = route.data?.onlyAnonymous ?? false;
    //     return true;
    // }

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        const requiresLogin = route.data.requiresLogin ?? false;
        const onlyAnonymous = route.data.onlyAnonymous ?? false;
        const allowNoCompanySelected = route.data.allowNoCompanySelected ?? false;
        const ifAndOnlyIfSetupIsNotComplete = route.data.ifAndOnlyIfSetupIsNotComplete ?? false;
        const ifAndOnlyIfPaymentIsNotComplete = route.data.ifAndOnlyIfPaymentIsNotComplete ?? false;

        if (requiresLogin && onlyAnonymous)
            throw new Error(
                'Cannot have requiresLogin and onlyAnonymous for the same page'
            );

        if (requiresLogin) {
            let res = await this.authService.isLoggedIn();
            if (res) {
                if (await this.checkCompanySetup(state, allowNoCompanySelected, ifAndOnlyIfSetupIsNotComplete, ifAndOnlyIfPaymentIsNotComplete) === false)
                    return false;
                res = await this.checkPermission(route);
                return res;
            } else {
                this.router.navigate(['/auth/login'], {
                    queryParams: { returnUrl: state.url },
                });
                return false;
            }
        }

        if (onlyAnonymous) {
            let res = await this.authService.isLoggedIn();
            if (!res) return true;
            this.router.navigate(['/home']);
            return false;
        }

        return true;
    }

    async checkCompanySetup(state: RouterStateSnapshot, allowNoCompanySelected: boolean, ifAndOnlyIfSetupIsNotComplete: boolean, ifAndOnlyIfPaymentIsNotComplete: boolean) {
        let currentCompanyId = this.contextService.companyId$.getValue();
        let userCompanies = this.contextService.employeeCompanies$.getValue();

        if (!this.contextService.isSysAdmin() && currentCompanyId == 0 && allowNoCompanySelected === false) {
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }

        if (ifAndOnlyIfSetupIsNotComplete && this.contextService.isSysAdmin()) {
            await this.router.navigateByUrl('home');
            return false;
        }

        if (userCompanies.length > 0 && currentCompanyId > 0) {
            if (userCompanies.find((com) => com.id == currentCompanyId)!.isSetupComplete === true && ifAndOnlyIfSetupIsNotComplete === false 
                && this.featureService.checkAccess('tc-check-payment-is-complete')) {
                if (this.contextService.isPaymentMethodSet$.getValue() == false &&  ifAndOnlyIfPaymentIsNotComplete == false) {
                    await this.router.navigateByUrl('payment-offer');
                    return false;
                } 
                return true;
            } else if (userCompanies.find((com) => com.id == currentCompanyId)!.isSetupComplete === false && ifAndOnlyIfSetupIsNotComplete === true) {
                return true;
            } else if (userCompanies.find((com) => com.id == currentCompanyId)!.isSetupComplete === false && ifAndOnlyIfSetupIsNotComplete === false) {
                await this.router.navigateByUrl('carrier-setup');
                return false;
            } else if (userCompanies.find((com) => com.id == currentCompanyId)!.isSetupComplete === true && ifAndOnlyIfSetupIsNotComplete === true) {
                await this.router.navigateByUrl('home');
                return false;
            }
        }

        return true;
    }

    async accessDenied() {
        this.sharedService.alertDangerMessage(
            'Access Denied',
            "You don't have permission to access this section"
        );
        setTimeout(() => {
            this.router.navigate(['/home']);
            window.location.reload();
        }, 1000);
    }

    async checkPermission(route: ActivatedRouteSnapshot) {
        if (environment.permissionBypass) {
            return true;
        }

        const requiresPermission = route.data?.requiresPermission ?? true;
        if (!requiresPermission) {
            return true;
        }

        await this.permissionsService.getAllUserPermissions(false);
        const PERMISSIONS_KEY = 'user-permissions';
        const permissionList = localStorage.getItem(PERMISSIONS_KEY)
            ? JSON.parse(localStorage.getItem(PERMISSIONS_KEY) ?? '')
            : null;
        if (permissionList == null || (permissionList && !permissionList.userResourcesPermissions.some((up: any) => up.permissions.length > 0))) {
            this.router.navigate(['/no-permissions']);
            return false;
        }

        if (route.data?.permissionType) {
            let permissionResource = route.data?.permissionResource;
            let permissionType = route.data?.permissionType;

            switch (permissionType) {
                case PermissionsEnum.List:
                    if (this.permissionsService.isListGranted(permissionResource)) {
                        return true;
                    } else {
                        this.accessDenied();
                        return false;
                    }
                case PermissionsEnum.View:
                    if (this.permissionsService.isViewGranted(permissionResource)) {
                        return true;
                    } else {
                        this.accessDenied();
                        return false;
                    }
                case PermissionsEnum.Edit:
                    if (this.permissionsService.isEditGranted(permissionResource)) {
                        return true;
                    } else {
                        this.accessDenied();
                        return false;
                    }

                case PermissionsEnum.SubList:
                    if (
                        this.permissionsService.isSubListGranted(permissionResource)
                    ) {
                        return true;
                    } else {
                        this.accessDenied();
                        return false;
                    }
                case PermissionsEnum.SubView:
                    if (
                        this.permissionsService.isSubViewGranted(permissionResource)
                    ) {
                        return true;
                    } else {
                        this.accessDenied();
                        return false;
                    }

                case PermissionsEnum.Add:
                    if (this.permissionsService.isAddGranted(permissionResource)) {
                        return true;
                    } else {
                        this.accessDenied();
                        return false;
                    }
            }
        }
        return true;
    }
}

export const AccessGuard: CanActivateFn = (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    return inject(AccessGuardService).canActivate(next, state);
};
